import React, { PropsWithChildren } from 'react';

import styles from './contacts.module.scss';

export function Contacts(props: PropsWithChildren<{}>): JSX.Element {
    return <div className={styles.contacts} {...props} />;
}

type ContactsItemProps = PropsWithChildren<{
    label: string;
    to: string;
}>;

export function ContactsItem(props: ContactsItemProps): JSX.Element {
    const { label, to, children, ...restProps } = props;

    return (
        <div className={styles.contacts__item} {...restProps}>
            <div>{label}</div>
            <a href={to}>{children}</a>
        </div>
    );
}
