import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { twLink } from 'constants/constants';
import { ArrowSmall } from 'components/arrow/arrow';
import { ContactsItem } from 'components/contacts/contacts';
import { Logo } from 'components/logo/logo';
import {
    NotFound,
    NotFoundBackground,
    NotFoundBackgroundLayer,
    NotFoundBody,
    NotFoundContacts,
    NotFoundHeader,
    NotFoundInfo,
} from 'components/not-found/not-found';
import { SocialLinks } from 'components/social-links/social-links';

export default function NotFoundPage(): JSX.Element {
    const data = useStaticQuery(imgQuery);

    return (
        <>
            <Helmet>
                <title>Page not found</title>
                <meta name="description"
                      content="P1K uses cutting-edge AI technologies to tackle a variety of modern challenges — from education dishonesty to legal paperwork overload." />
                <meta property="og:title" content="Page not found" />
                <meta property="og:description" content="P1K uses cutting-edge AI technologies to tackle a variety of modern challenges — from education dishonesty to legal paperwork overload." />
            </Helmet>
            <NotFound>
                <NotFoundBackground>
                    <NotFoundBackgroundLayer
                        style={{
                            backgroundImage: `url(${require('../img/circles-md.svg')})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                        }}
                    ></NotFoundBackgroundLayer>
                    <NotFoundBackgroundLayer>
                        <Img
                            fluid={data.clouds.childImageSharp.fluid}
                            style={{
                                position: 'absolute',
                                left: 0,
                                right: 0,
                                bottom: 0,
                            }}
                        />
                    </NotFoundBackgroundLayer>
                </NotFoundBackground>

                <NotFoundHeader>
                    <Link to="/">
                        <Logo />
                    </Link>
                    <SocialLinks color="dark" />
                </NotFoundHeader>

                <NotFoundBody>
                    <NotFoundInfo>
                        <em>404</em>
                        <span>A dead end is a good place to turn around.</span>
                        <Link to="/">
                            <span>go to homepage</span>
                            <ArrowSmall />
                        </Link>
                    </NotFoundInfo>
                    <NotFoundContacts>
                        <ContactsItem label="collaborate" to="mailto:letscollaborate@p1k.org">
                            → letscollaborate@p1k.org
                        </ContactsItem>
                        <ContactsItem label="follow" to={twLink}>
                            → tw: @phase1karma
                        </ContactsItem>
                        <ContactsItem label="talk" to="mailto:letstalk@p1k.org">
                            → letstalk@p1k.org
                        </ContactsItem>
                    </NotFoundContacts>
                </NotFoundBody>
            </NotFound>
        </>
    );
}

export const imgQuery = graphql`
    query {
        clouds: file(relativePath: { eq: "404/clouds-1.png" }) {
            childImageSharp {
                fluid(maxWidth: 2732, srcSetBreakpoints: [2732], quality: 95) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
    }
`;
