import React, { CSSProperties, PropsWithChildren } from 'react';

import { Grid } from 'components/grid/grid';

import styles from './not-found.module.scss';

export function NotFound(props: PropsWithChildren<{}>): JSX.Element {
    return <div className={styles.notFound} {...props} />;
}

export function NotFoundBackground(props: PropsWithChildren<{}>): JSX.Element {
    return <div className={styles.notFound__background} {...props} />;
}

export function NotFoundBackgroundLayer(props: PropsWithChildren<{ style?: CSSProperties }>): JSX.Element {
    return <div className={styles.notFound__backgroundLayer} {...props} />;
}

export function NotFoundHeader(props: PropsWithChildren<{}>): JSX.Element {
    return (
        <Grid tag="header">
            <div className={styles.notFound__headerBody} {...props} />
        </Grid>
    );
}

export function NotFoundBody(props: PropsWithChildren<{}>): JSX.Element {
    return (
        <Grid tag="main">
            <div className={styles.notFound__body} {...props} />
        </Grid>
    );
}

export function NotFoundInfo(props: PropsWithChildren<{}>): JSX.Element {
    return <div className={styles.notFound__info} {...props} />;
}

export function NotFoundContacts(props: PropsWithChildren<{}>): JSX.Element {
    return <div className={styles.notFound__contacts} {...props} />;
}
